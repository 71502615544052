.user-management-fiter {
    fieldset {
        width: 12%;
        margin-right: 8px;

        &.rangepicker-calendra {
            width: 18%;
        }
    }

    .more-filter-option {
        fieldset {
            margin-bottom: 15px;

        }
    }
}

.top-heading {
    align-items: center;
    justify-content: space-between;

    &.d-flex {
        display: flex;
    }

    .right-btn {
        display: flex;
        align-items: center;

        .btn-line {
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 16px;
            }
        }
    }
}

.user-tabs-outer {
    margin-bottom: 20px;

    h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
    }

    .user-tabs {
        background: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 0px 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ul {
            display: flex;
            align-items: center;
            padding-top: 20px;

            li {
                text-align: center;
                margin-right: 50px;
                border-bottom: 2px solid transparent;
                padding-bottom: 20px;

                label {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;

                    &.all-user {
                        color: $heading-color;
                    }

                    &.total-user {
                        color: #487CFD;
                    }

                    &.active-user {
                        color: #34C48B;
                    }

                    &.inactive-user {
                        color: #F83939;
                    }

                    &.inprocess-user {
                        color: #FFA340;
                    }
                }

                span {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 16px;
                    display: block;
                    color: rgba($heading-color, 0.6);
                }

                &.active {
                    border-bottom: 2px solid $accent-color;
                }
            }
        }
    }
}

.user-managemnt-table {
    table {
        tbody {
            tr {
                td {
                    a {
                        color: rgba($heading-color, 0.6);
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.user-managemnt-detail-table {
    background: $white;
    border-radius: 8px;

    &.data-table {
        table {
            thead {
                tr {
                    th {
                        line-height: 18px;
                    }
                }
            }

            tr {

                td,
                th {
                    &:first-child {
                        width: 6%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }

                    &:nth-child(3) {
                        width: 8%;
                    }

                    &:nth-child(4) {
                        width: 13%;
                    }

                    &:nth-child(5) {
                        width: 6%;
                    }

                    &:nth-child(6) {
                        width: 8%;
                        white-space: normal;
                    }

                    &:nth-child(7) {
                        width: 9%;
                    }

                    &:nth-child(8) {
                        width: 8%;
                    }

                    &:nth-child(9) {
                        width: 7%;
                        white-space: normal;
                    }

                    &:nth-child(10) {
                        width: 6%;
                        white-space: normal;
                    }

                    &:last-child {
                        width: 14%;
                    }
                }

                .action-btn {
                    display: flex;
                    align-items: center;

                    li {
                        height: 32px;
                        width: 32px;
                        min-width: 32px;
                        border-radius: 4px;
                        border: 1px solid $border-color;
                        margin-right: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        cursor: pointer;

                        a {
                            color: rgba($heading-color, 0.6);
                        }
                    }


                }
            }
        }
    }
}

.status-active {
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: #44D7B6;
        position: relative;
        top: -2px;
        border-radius: 100%;

    }
}


.status-inprocess {
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: #EE8F00;
        position: relative;
        top: -2px;
        border-radius: 100%;
        margin-right: 10px;
        display: inline-block;
    }
}


.user-action-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0px 40px 0 0;

    .btn-line {
        margin-left: 10px;
        min-width: auto;
        padding: 0px 20px;
    }
}

.user-managemnt-detail-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;

    .search-bx {
        margin-right: 0px;
        width: 250px;
        margin-bottom: 0px;
    }

    .tab-line {
        .tab-list {
            .nav-item {
                margin-right: 30px;
            }
        }
    }
}

.user-management-detail-tab-outer {
    background: $white;
    border-radius: 8px;
}

.anchor-txt {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba($heading-color, 0.6);
    text-decoration: underline;

}


.allowance-card-table {
    margin-bottom: 40px;

    table {
        width: 70%;
    }
    &.allowance-card{
        table {
            width: 90%;
        }
    }
}

.Configuration-filter {
    border-bottom: 1px solid $border-color;
    padding-bottom: 40px;
    margin-bottom: 40px;

    .heading-txt {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
        padding-bottom: 20px;
        display: block;
    }

    .configuration-filed-outer {
        fieldset {
            margin-right: 15px;
        }

        table {
            th {
                text-align: left;
                padding-bottom: 8px;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: $heading-color;
            }

            td {
                padding-bottom: 20px;
            }

            th,
            td {
                &:nth-child(1) {
                    width: 40%;
                }

                &:nth-child(2) {
                    width: 15%;
                    margin-left: 10px;
                }

                &:nth-child(3) {
                    width: 15%;
                    margin-left: 10px;
                }

                &:nth-child(4) {
                    width: 15%;
                    margin-left: 10px;
                }
                &:nth-child(5) {
                    width: 15%;
                    margin-left: 10px;
                }
            }
        }

        .chip-set {
            margin-top: 20px;
            .chips {
                display: inline-flex;
                // position: relative;
                background-color: rgba(0,0,0,.12);
                // color: rgba(0,0,0,.87);
                height: 32px;
                padding: 5px 10px;
                // border-width: 0;
                // outline: none;
                cursor: pointer;
                border-radius: 16px;
                margin-right: 5px;
            }
        }
    }

    .bm-configuration-filed-outer {
        fieldset {
            margin-right: 15px;
        } 
        table {
            width: 60%;
            th {
                text-align: left;
                padding-bottom: 8px;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: $heading-color;
            } 
            td {
                padding-bottom: 20px;
            } 
            th,
            td {
                &:nth-child(1) {
                    width: 60%;
                }

                &:nth-child(2) {
                    width: 40%;
                    margin-left: 10px;
                } 
            }
        } 
        .chip-set {
            margin-top: 20px;
            .chips {
                display: inline-flex; 
                background-color: rgba(0,0,0,.12); 
                height: 32px;
                padding: 5px 10px; 
                cursor: pointer;
                border-radius: 16px;
                margin-right: 5px;
            }
        }
    }
    .et2-config-filed-outer {
        fieldset {
            margin-right: 15px;
        } 
        table {
            width: 100%;
            th {
                text-align: left;
                padding-bottom: 8px;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: $heading-color;
            } 
            td {
                padding-bottom: 20px;
            } 
            th,
            td {
                &:nth-child(1) {
                    width: 24%;
                }

                &:nth-child(2) {
                    width: 22%;
                    margin-left: 10px;
                    padding-left: 20px;
                }

                &:nth-child(3) {
                    width: 22%;
                    margin-left: 10px;
                }

                &:nth-child(4) {
                    width: 22%;
                    margin-left: 10px;
                }
                &:nth-child(5) {
                    width: 10%;
                    margin-left: 10px;
                }
            }
        } 
        .chip-set {
            margin-top: 20px;
            .chips {
                display: inline-flex; 
                background-color: rgba(0,0,0,.12); 
                height: 32px;
                padding: 5px 10px; 
                cursor: pointer;
                border-radius: 16px;
                margin-right: 5px;
            }
        }
    }

    .et3-config-filed-outer {
        fieldset {
            margin-right: 15px;
        } 
        table {
            width: 90%;
            th {
                text-align: left;
                padding-bottom: 8px;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: $heading-color;
            } 
            td {
                padding-bottom: 20px;
            } 
            th,
            td {
                &:nth-child(1) {
                    width: 10%;
                }

                &:nth-child(2) {
                    width: 20%;
                    margin-left: 10px;
                    padding-left: 20px;
                }

                &:nth-child(3) {
                    width: 20%;
                    margin-left: 10px;
                }

                &:nth-child(4) {
                    width: 20%;
                    margin-left: 10px;
                }
                &:nth-child(5) {
                    width: 20%;
                    margin-left: 10px;
                }
                &:nth-child(6) {
                    width: 10%;
                    margin-left: 10px;
                }
            }
        } 
        .chip-set {
            margin-top: 20px;
            .chips {
                display: inline-flex; 
                background-color: rgba(0,0,0,.12); 
                height: 32px;
                padding: 5px 10px; 
                cursor: pointer;
                border-radius: 16px;
                margin-right: 5px;
            }
        }
    }
    .btn-line {
        min-width: auto;
        padding: 0px 20px;
    }
}

.user-incentive-type2 {
    table {
        width: 70%;

        th,
        td {
            width: 100px;

            &:nth-child(3) {
                text-align: center;
            }
        }
    }
}

.add-incentive-accordion {
    margin-top: 20px;
}

.redeemable-select-finacer-list {
    margin-bottom: 40px;
    .sub-heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
        padding-bottom: 20px;
        display: block;
    }
    table{
     width: 40%;   
    }
}

.booster-txt-filed{
    margin-bottom: 40px;
    span{
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
    }
    .material{
         .form-input{
            width: 235px;

         }
        }
}

.extra-bonus-bro-filter{
    width: 100%;
    .basic-detail-filter{
        fieldset{
            width: 15%;
        }
        .rangepicker-calendra {
            width: 18%;
        }
    }    
}

.w-100{
    width: 100%;
}

.special-sheme-incentive-amt{
    margin-bottom: 40px;
    span{
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
    }
    .material{
         .form-input{
            width: 370px;

         }
        }
}
.special-sheme-filed{
    table{
        width: 60%;
    }
}


.add-incentive-popup{
    .add-incentive-data{
        ul{
            li{
                font-weight: 400;
                font-size: 14px;
                line-height: 45px;
                border-bottom: 1px solid $border-color;
                position: relative;
                &::after{
                    content: "\e919";
                    font-family: "dc" !important;
                    position: absolute;
                    right: 0px;
                    font-size: 24px;
                    color: rgba($heading-color, 0.5);
                }
                &:last-child{
                    border-bottom: 0px;
                }
            }
        }
    }
}

.config-heading{
    font-weight: bold;
    color: black;
    font-size: 18px;
    margin-bottom: 20px;
    display: block;
}

.bg-check-ic {
    background:#34C48B;
    height: 15px;
    width:15px;
    border-radius: 100%;
    position: relative;
    margin-right: 10px;
    .ic-check{
        color: #fff !important;
        position: absolute;
        top: -13px;
        left: 3px;
        z-index: 99;
        FONT-SIZE: 10PX !important;
        FONT-WEIGHT: BOLD;
        TEXT-ALIGN: CENTER;
    }
}

.disbursalamount{
    position: relative;
    left: -50px;
}

.disbursalConfig{
    color:$heading-color !important;
    font-weight:500;
}



.ppdUpdate{
    text-align:center;
}
.ppd-devation-popup{ 
    table{
        border: 1px solid $border-color;
        border-top: 0px;
        thead{
            th{
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                background-color: #e3e7f1;
                width: 120px;
                z-index: 9;
                &:nth-child(2){
                    width: 120px;
                }
                &:nth-child(3){
                    width: 120px;
                }
            }

        }
    }
    .search-bx .material .form-input{
        padding-right:45px;
    }
    .data-table{
        max-height: 300px;
        overflow:auto;
    }
    .data-table table tbody tr:hover {
        background:$white;
    }
    .ppdSearchBox{
        display: flex;
        flex-wrap: wrap;
    }
    .ppdUpdate{
        text-align:center;
        margin-top: 20px;
    }
    .ppd-upload-ic{
        right: 15px !important;
        left: initial !important;
        top:20px !important
    }
    .datacount{
        width: 100%;
        padding-top:10px;
    }
    .datacountMatch{
       color:#3BC04D
    }
    .datacountMisMatch{
        color:#F40C1A
    }
    
}
.finance {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $white;
    background: #3bc04d;
    border-radius: 4px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    margin-left: 8px;
  }
  .re-finance {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $white;
    background: #0384f2;
    border-radius: 4px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    margin-left: 8px;
  }
  .ubrf {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $white;
    background: #FF7F05;
    border-radius: 4px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    margin-left: 8px;
  }

  .ppd-option{
    width: 85px;
    .css-1pahdxg-control,
    .css-yk16xz-control, .material, .react-select__control{
        border: 0px !important;
        &:hover {
            border:0px !important ;
        }
    }
    .react-select__placeholder{
        display: none !important;
    }
  }
  .upload-csv-ppd{
    cursor: pointer;
    height: 100%;
    right: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
    padding: 0px !important;
}
.ppd-upload-csv-div{
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 100%;
}

.operator{
    width:120px;
}
