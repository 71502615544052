.total-incetive-outer{
    background: $white;
    border-radius: 8px;
    padding: 30px 20px;
    margin-top: 20px;
    h2{
        padding-bottom: 20px;
    }
    .total-incentive-card-outer{
      display: flex;
      margin: 0px -5px;
      padding-bottom: 40px;
        .total-incentive-card{
            box-shadow: 0px 2px 4px 0px #24272C0F;
            border: 1px solid #E0E0E0;
            border-radius: 8px;
            padding: 20px;
            width: calc(16.66% - 10px);
            margin: 0px 5px;
            label{
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                color: $heading-color;
                display: block; 
                padding-bottom: 15px;
            }
            span{
                font-size: 12px;
                font-weight: 400;
                color: $heading-color;
                display: block; 

            }
        }
    }
    
    .incentive-breakups-card-outer{
        .col-md-4{
            margin-bottom: 20px;
        }
        .incentive-breakups-card{
            box-shadow: 0px 2px 4px 0px #24272C0F;
            border: 1px solid #E0E0E0;
            border-radius: 8px;
            background: $white;
            height: 100%;
            .card-heading{
                font-size: 14px;
                font-weight: 500;
                line-height: 30px;
                text-align: left;
                background: #EFF2F9;
                padding: 10px 20px;
                border-radius: 8px 8px 0 0;
                color: $heading-color;
            }
            ul{
                padding: 0px 20px;
                li{
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: $heading-color;
                padding: 10px 0;
                display: flex;
                border-bottom: 1px solid $border-color;
                    label{
                        width: calc(60% - 10px);
                        padding-right: 10px;
                    }
                    span{
                        width: calc(40% - 10px);
                        padding-left: 10px;
                    }
                    &:last-child{
                        border-bottom: 0px;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 30px;

                    }
                }
            }
        }
    }
}