.add-user-form-sec {
    display: flex;

    .menu-tab-left-panel {
        width: 23%;
        background: $white;
        padding: 25px 33px;
        position: relative;

        ul {
            li {
                position: relative;

                a {
                    color: rgba(0, 0, 0, 0.5);
                    margin-bottom: 20px;
                    display: inline-block;
                    width: 100%;

                    .img-type {
                        background: #fff;
                        border: 2px solid rgba(0, 0, 0, 0.37);
                        height: 16px;
                        width: 16px;
                        margin-top: 2px;
                        margin-left: -15px;
                        margin-right: 15px;
                        vertical-align: top;
                        display: inline-block;
                        position: relative;
                        border-radius: 50%;
                        z-index: 2;
                    }

                    &.completed {
                        color: $heading-color;

                        .img-type {
                            background: #0bbddd;
                            border: 1px solid #0bbddd;
                            position: relative;

                            &:after {
                                content: "\e927";
                                position: absolute;
                                z-index: 2;
                                left: 0px;
                                top: 1px;
                                font-family: dc !important;
                                color: #fff;
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }
                    }

                    &.active {
                        color: $heading-color;

                        .img-type {
                            background: #0bbddd;
                            border: 1px solid #0bbddd !important;
                            position: relative;
                        }
                    }
                }

                .completed {
                    &:after {
                        content: "";
                        border-left: 1px solid #0bbddd;
                        left: -7px;
                        position: absolute;
                        top: 18px;
                        height: 100%;
                    }
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .route-files {
        width: calc(77% - 40px);
        margin: 20px 20px 0px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

    }
}

.fileUpload {
    background: $accent-color;
    border-radius: 8px;
    color: #fff;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 177px;
    height: 44px;
    line-height: 44px;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;

    input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);

    }

    .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
    }
}

.add-user-detail-filed {
    h2 {
        margin-bottom: 30px;
    }

    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
        padding: 0px 0 10px;
    }

    .br-top {
        margin-top: 10px;
        padding: 30px 40px;
        border-top: 1px solid $border-color;
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;
        display: block;
        text-align: right;

    }

    fieldset {
        margin-bottom: 30px;
    }
}

.add-profile-photo {
    padding-bottom: 30px;

    .add-img-txt {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        display: block;
        color: $heading-color;
    }

    .subheading {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding-bottom: 20px;
        display: block;
    }

}

.basic-detail-form {
    .info-icn {

        .ic-info-icon1,
        .ic-show-password {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        &.material {
            .form-input {
                padding-right: 30px;
            }
        }
    }
}

.add-photo-btn {
    display: flex;
    align-items: center;

    .preview-img {
        width: 124px;
        height: 81px;
        background: #F4F6FB;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        overflow: hidden;
    }
}

.upload-document-outer {
    margin: 0px -8px;
    display: flex;
    flex-wrap: wrap;

}

.img-bx-select-opt {
    margin: 0px 8px 30px;

    .image-bx {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        width: 160px;
        height: 140px;
        background: #F4F6FB;
        border-radius: 10px;
        position: relative;

        &.more-file-upload {
            flex-direction: column;

            .ic-add {
                font-size: 30px;
                display: block;
                color: rgba($heading-color, 0.6);
                margin-bottom: 10px;
            }

            p {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                color: rgba($heading-color, 0.6);
            }

            input[type="file"] {
                opacity: 0;
                height: 140px;
                width: 100%;
                outline: 0;
                z-index: 1;
                position: absolute;
                top: 0;
                cursor: pointer;
            }
        }
    }

    .document-name {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: rgba($heading-color, 0.87);
        display: block;
        text-align: center;
        padding-top: 10px;
    }
}


.model-popup-outer {
    .modal-body {
        max-height: 470px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }

    .modal-main {
        border-radius: 8px;
        padding: 40px 40px;

        h2 {
            padding: 0px 0 24px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }
    }

    &.add-incentive-popup {
        .modal-main {
            width: 410px;
        }
    }
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list,
.slick-slider {
    position: relative;
    display: block;
}

.slick-initialized .slick-slide {
    display: block;
    text-align: center;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-arrow {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 100%;

    &.slick-prev {
        left: -20px;

        &::before {
            content: "\e92e";
            font-family: "dc" !important;
            color: $heading-color;
            font-size: 20px;
            position: relative;
            left: 2px;
            top: 4px;

        }
    }

    &.slick-next {
        position: absolute;
        right: -20px;

        &::before {
            content: "\e92f";
            font-family: "dc" !important;
            color: $heading-color;
            font-size: 20px;
            position: relative;
            right: 0px;
            top: 4px;

        }
    }

    &.slick-next,
    &.slick-prev {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        padding: 0;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor: pointer;
        border: none;
    }


}


.vechile-document-popup {
    .modal-main {
        width: 660px;
    }
    .btn-re-upload{
        position: absolute;
        right: 30px;
    }
}


.image-opt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            padding: 0px 5px;

            a {
                background: #ffffff;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
                border-radius: 10px;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 20px;
            }

            .ic-history {
                font-size: 17px;
            }
        }
    }

    .btn-submission-approved {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-line {
            min-width: auto;
            padding: 0px 10px;
            height: 34px;
            line-height: 31px;
            font-size: 12px;
            font-weight: normal;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    &.doc-image-opt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        ul {
            margin-bottom: 0px;
        }

        .btn-submission-approved {
            justify-content: flex-end;
        }
    }
}

.role-detail-preview {
    ul {
        display: flex;

        li {
            display: block;
            width: 160px;
            margin-right: 10px;
            &.address-txt{
                width: calc(100% - 200px);
            }

            span {
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                color: rgba($heading-color, 0.6);
                display: block;
            }

            label {
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $heading-color;
            }
        }
    }
}

.preview-confirmation {
    .br-bottom {
        border-bottom: 1px solid $border-color;
        padding-bottom: 40px;
        margin-bottom: 40px;
    }
    .profile-photo{
        width: 124px;
        height: 81px;
        background: #F4F6FB;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 46px;
    }
    .top-heading {
        padding: 0px;
    }
.preview-basic-details{
    ul{
        flex-wrap: wrap;
        li{
            padding-bottom: 15px;
        }
    }
}
}

.edit-opt{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color:$heading-color;
    text-decoration: underline;
    padding-bottom: 30px;
}