
.dealer-detail-table {
    padding-top: 30px;

    .custom-control {
        display: inline-block;
    }

    .ic-keyboard_arrow_down {
        font-size: 26px;
    }

    .data-table {
        table {
            tr {

                th,
                td {
                    &:nth-child(1) {
                        width: 5%;
                        padding-left: 20px;
                    }

                    &:nth-child(2) {
                        width: 12%;
                    }

                    &:nth-child(3) {
                        width: 12%;
                    }

                    &:nth-child(4) {
                        width: 12%;
                    }

                    &:nth-child(5) {
                        width: 12%;
                    }

                    &:nth-child(6) {
                        width: 15%;
                    }

                    &:nth-child(7) {
                        width: 12%;
                    }

                    &:last-child {
                        width: 12%;
                    }

                    &.net-amt-txt {
                        background: rgba(255, 241, 214, 0.4);
                    }
                }

                td {
                    vertical-align: top;
                }

                th {
                    .info-icn {
                        display: inline-block;
                        position: relative;

                        .info-text-detail {
                            left: -212px;
                        }
                    }
                }

                .loan-id-arrow {
                    &::before {
                        content: "\e917";
                        font-family: dc !important;
                        font-size: 24px;
                        position: relative;
                        top: 6px;
                        margin-right: 8px;
                        cursor: pointer;
                    }
                }

                &.active {
                    background: #F6F6F6 !important;
                    border-bottom: 0px;

                    .loan-id-arrow {
                        &::before {
                            content: "\e91a";
                            font-family: dc !important;
                            font-size: 24px;
                            position: relative;
                            top: 6px;
                            margin-right: 8px;
                        }
                    }
                }
            }

            .subtable {
                thead {
                    tr {
                        background: #E3E7F1;

                        th {
                            &:first-child {
                                padding-left: 50px !important;
                            }
                        }
                    }
                }

                td {
                    background: #F6F6F6;
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                    padding-top: 0px;
                    vertical-align: middle;

                    table {
                        tr {
                            th, td {
                                width: 7%;

                                &:first-child{
                                    width: 10%;
                                }

                                
                                &:nth-child(2) {
                                    width: 10%;
                                }
                                

                                &:nth-child(4) {
                                    width: 11%;
                                }

                            }
                            td {
                                &:nth-child(4) {
                                    padding-left: 5px !important;
                                }

                                &:last-child{
                                    padding-left: 7px !important;
                                }
                            }
                        }
                    }
                }

                table {
                    width: 100%;
                    overflow-y: auto;
                    display: block;

                    tbody {
                        td {
                            background: $white !important;
                            padding-left: 20px !important;
                            padding-right: 20px !important;
                            padding-top: 13px;

                            &:first-child {
                                padding-left: 50px !important;
                            }
                        }
                    }
                }

                tr {
                    &.rejected {
                        td {
                            opacity: 0.4;

                            &:last-child {
                                color: #FF3636;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .ic-file_icn {
                position: absolute;
                margin-left: -22px;
                color: #000;
                font-size: 17px;
            }

            .ic-check {
                color: #59B74A;
            }

            .ic-clearclose {
                color: #FF3636;
            }
        }

        .link-txt {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .action-btn {
        display: flex;
        align-items: center;

        li {
            height: 32px;
            width: 32px;
            min-width: 32px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
            color: $heading-color;

            .ic-email1 {
                font-size: 22px;
            }

            .ic-otp {
                font-size: 23px;
            }
        }
    }
}




.btn-search-reset {
    display: flex;
    align-items: center;

    .btn-primary {
        min-width: 112px;
        margin-right: 20px;
        margin-left: 20px;
    }

    .btn-reset {
        background: transparent;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        border: 0px;
        color: $heading-color;
    }

    .btn-bulk-upload {
        min-width: 120px;
        margin-left: 200px;
    }

    .download-report {
        min-width: 200px !important;
        margin-left: 180px !important;
        margin-top: 40px !important;
    } 

}

 


.bulk-uploader-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 0;
    .display-box {
     border: 1px solid #ccc;
     width: 100%;
     padding: 0px 20px;
      .upload-text {
        .dealer-upload-text {
            justify-content: space-between;
            display : flex;
            align-items: center;
            border-bottom: 2px solid #ccc;
            padding-bottom: 24px;
            position: relative;
            &:last-child{
                border-bottom: none;
            }
            .file-btn{
                margin: 0px !important
            }
           
        }
       
      }
      .upload-image-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      
    }
  }


.dealer-upload-popup{
    .modal-main {
        width: 700px;
    }
}

.dealer-upload-btn {
    max-width: 177px;
    position: relative;
}

.dealer-config-btn {
    .btn-line {
        min-width: auto;
        padding: 0 20px;
    }
}

.dealer-config-subheading {
    h2 {
        font-size: 18px;
    }
}

.dealer-financer-popup{
    .modal-main {
        width: 1160px;
        max-width: 100%;
        padding-bottom: 20px;

        .modal-body {
            max-height: calc(100vh - 360px) !important;
        }
    }
}

.financier-group-config {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
        width: calc(33.33% - 15px);
        padding-right: 15px;
        display: flex;
        align-items: center;
        margin-bottom : 20px;

        .financer-group-label {
            width: 100px;
        }
        .single-select {
            width: 100px;
        }
        .input {
            width: 120px;
        }
    }

    .validation-error {
        bottom: -15px;
    }
}

.category-config-save{
    display: block;
    text-align: center;
    width: 100%;
    .btn-line {
        min-width: auto !important;
        padding: 0 30px;
    }
    
}

.deviation-am {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    .single-select{
        width: 200px;

    }
}

.deviation-model-tabs {
    .nav-pills {
        padding-top: 0px !important;
    }
}


.react-select__control--is-disabled {
    .react-select__value-container {
        background: rgb(242 242 242) !important;
    }
}

.bg-white {
    background: $white;
}

.validation-error {
    position: absolute;
    width: 100%;
    bottom: -18px;
    left: 0;
}

.dealer-filters {
    justify-content: space-between !important;

    .dealer-filter-list {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .btn-calculate-upload {
        align-items: center;

        .btn-line {
            margin-left: 10px;
        }
    }

    .download-report {
        min-width: 200px !important;
        margin-left: 180px !important;
        margin-top: 40px !important;
    } 
}

