.dashboard-filter-top {
    background: $white;
    padding: 20px;
    border-radius: 8px;

    &.loan-filter-bg {
        fieldset {
            margin-bottom: 0px;
        }

        .more-filter {
            .dropdown.show {
                .dropdown-menu {
                    transform: translate(-60%, 60px) !important;
                    inset: initial !important;
                }
            }

            fieldset {
                margin-bottom: 20px;
            }

            .btn-submit-reset {
                &.more-filter-btn {
                    margin-top: 0px;
                }
            }
        }

        .dateragefiled {
            width: 130px;
        }
    }
}

.payout-summary-tabs {
    background: $white;
    border-radius: 8px;
    position: relative;

    .nav-pills {
        padding: 25px 30px 0;
        // position: relative;
    }

    .tab-line {
        .tab-list {
            .nav-item {
                margin-right: 40px;
            }
        }
    }

    .tab-bg {
        .tab-list {
            .nav-item {
                .nav-link {
                    padding: 0px 10px;
                }
            }
        }
    }


}

.pending-record-txt {
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: $heading-color;
    display: flex;
    align-items: center;
    padding-right: 20px;

    span {

        color: #DF2A2A;
        padding-right: 2px;
    }

    a {
        color: $heading-color;
        padding-left: 2px;
        text-decoration: underline;
    }
}

.dashboard-outer {
    .sub-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .report-heading-right-panel {
        display: flex;
        align-items: center;



        .ic-check {
            color: #59B74A;
            font-size: 18px;
            padding-right: 5px;
        }

        .ic-clearclose {
            color: #FF3636;
            font-size: 18px;
            padding-right: 5px;
        }

        .ic-export {
            font-size: 16px;
            padding-right: 5px;
        }

        .btn-line {
            padding: 0px 20px;
            display: flex;
            align-items: center;

            .ic-upload {
                font-size: 20px;
                padding-right: 5px;
            }

            &:hover {
                i {
                    color: $white;
                }
            }
        }
    }

    .model-popup-outer {
        .ic-clearclose {
            color: $heading-color;
        }
    }
}

.approve-status-tabs {
    margin-top: 10px;
}

.payout-summery-right-panel {
    position: absolute;
    right: 15px;

    // z-index: 1;
    ul {
        display: flex;
        align-items: center;

        li {
            color: rgba($heading-color, 0.7);
            font-size: 22px;
            padding-right: 15px;
            text-align: center;

            span {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                display: block;
            }

            label {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                display: block;
                color: rgba($heading-color, 0.6);
            }

            .total-payout-txt {
                color: #34C48B;
            }

            .total-incentives-txt {
                color: #487CFD;
            }

            .total-allowance {
                color: #FFA340;
            }

            .paid-rp-txt {
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                font-weight: 500;
            }

            .progress {
                background: #a6a6a6;
                height: 12px;
                border-radius: 10px;
                cursor: pointer;

                .progress-bar {
                    background: #34C48B;
                    height: 12px;
                    border-radius: 10px;
                    font-size: 9px;

                    .visually-hidden {
                        background: $heading-color;
                        display: none;
                        border-radius: 5px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #fff;
                        padding: 5px 15px;
                        position: absolute;
                        bottom: -30px;

                    }

                    &:hover {
                        .visually-hidden {
                            display: block;
                        }

                    }
                }
            }

        }
    }
}

.payout-summary-table {
    padding-top: 30px;

    table {

        th,
        td {
            width: 15%;

            &:nth-child(2),
            &:nth-child(3) {
                width: 20%;
            }

            a {
                color: rgba($heading-color, 0.6);
                text-decoration: underline;
            }

        }

        tfoot {
            tr {
                background: rgba(255, 241, 214, 0.4);

                td {
                    padding: 13px 9px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: rgba($heading-color, 0.6);
                    text-align: left;

                    &:first-child {
                        padding-left: 30px;
                    }

                    &:last-child {
                        padding-right: 20px;
                    }
                }
            }
        }
    }
}

.payout-detail-table {
    padding-top: 30px;

    .custom-control {
        display: inline-block;
    }

    .ic-keyboard_arrow_down {
        font-size: 26px;
    }

    .data-table {
        table {
            tr {

                th,
                td {
                    &:nth-child(1) {
                        width: 5%;
                        padding-left: 20px;
                    }

                    &:nth-child(2) {
                        width: 8%;
                    }

                    &:nth-child(3) {
                        width: 8%;
                    }

                    &:nth-child(4) {
                        width: 8%;
                    }

                    &:nth-child(6) {
                        width: 10%;
                    }

                    &:nth-child(7) {
                        width: 9%;
                    }

                    &:nth-child(8) {
                        width: 9%;
                    }

                    &:nth-child(9) {
                        width: 9%;
                    }

                    &:nth-child(10) {
                        width: 8%;
                    }

                    &:last-child {
                        width: 11%;
                    }

                    &.net-amt-txt {
                        background: rgba(255, 241, 214, 0.4);
                    }
                }

                td {
                    vertical-align: top;
                }

                th {
                    .info-icn {
                        display: inline-block;
                        position: relative;

                        .info-text-detail {
                            left: -212px;
                        }
                    }
                }

                .loan-id-arrow {
                    &::before {
                        content: "\e917";
                        font-family: dc !important;
                        font-size: 24px;
                        position: relative;
                        top: 6px;
                        margin-right: 8px;
                        cursor: pointer;
                    }
                }

                &.active {
                    background: #F6F6F6 !important;
                    border-bottom: 0px;

                    .loan-id-arrow {
                        &::before {
                            content: "\e91a";
                            font-family: dc !important;
                            font-size: 24px;
                            position: relative;
                            top: 6px;
                            margin-right: 8px;
                        }
                    }
                }
            }

            .subtable {
                thead {
                    tr {
                        background: #E3E7F1;

                        th {
                            &:first-child {
                                padding-left: 50px !important;
                            }
                        }
                    }
                }

                td {
                    background: #F6F6F6;
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                    padding-top: 0px;
                    vertical-align: middle;

                    table {
                        tr {
                            th {
                                width: 11%;

                                &:nth-child(4) {
                                    width: 14%;
                                }

                                &:nth-child(8) {
                                    width: 11%;
                                }

                            }
                        }
                    }
                }

                table {
                    width: 100%;
                    overflow-y: auto;
                    display: block;

                    tbody {
                        td {
                            background: $white !important;
                            padding-left: 20px !important;
                            padding-right: 20px !important;
                            padding-top: 13px;

                            &:first-child {
                                padding-left: 50px !important;
                            }
                        }
                    }
                }

                tr {
                    &.rejected {
                        td {
                            opacity: 0.4;

                            &:last-child {
                                color: #FF3636;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .ic-file_icn {
                position: absolute;
                margin-left: -22px;
                color: #000;
                font-size: 17px;
            }

            .ic-check {
                color: #59B74A;
            }

            .ic-clearclose {
                color: #FF3636;
            }
        }

        .link-txt {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .action-btn {
        display: flex;
        align-items: center;

        li {
            height: 32px;
            width: 32px;
            min-width: 32px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
            color: $heading-color;

            .ic-email1 {
                font-size: 22px;
            }

            .ic-otp {
                font-size: 23px;
            }
        }
    }
}

.truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.active-status,
.approve-status,
.inactive-status,
.reject-status,
.paid-status,
.pending-status {
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: #44D7B6;
        display: inline-block;
        border-radius: 100%;
        position: relative;
        top: -1px;
        margin-right: 3px;

    }
}

.inactive-status,
.reject-status {
    &::before {
        background: #E02020;
    }

    color: #E02020;
}

.paid-status {
    &::before {
        background: #3784F8;
    }
}

.pending-status {
    &::before {
        background: #FFA340;
    }
}

.txt-value-input {
    background: #ffffff;
    border: 1px solid #bbbbbb;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 10px;
    width: 90px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.info-icn {
    display: inline-block;
    position: relative;

    .info-text-detail {
        display: none;
        background: #1F1F1F;
        border-radius: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $white;
        padding: 5px 15px;
        position: absolute;
        top: -33px;
        left: -23px;

        &::after {
            content: "\e922";
            font-family: dc !important;
            position: absolute;
            bottom: -12px;
            color: $heading-color;
            font-size: 40px;
            left: 0px;
            right: 0px;
            margin: 0 auto;
            text-align: center;

        }
    }

    &:hover {
        .info-text-detail {
            display: block;
        }
    }

    .ic-info-icon1 {
        top: 2px;
        position: relative;
    }
}

.ucf-disbursal-popup {
    .modal-main {
        width: 860px;
    }

    .modal-body {
        max-height: 500px;
    }

    table {
        thead {
            display: block;

            tr {
                width: 100%;
                display: block;
                border-radius: 6px 6px 0 0;

                th {

                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    z-index: 10;
                    left: 0;
                    background: #e3e7f1;
                }
            }
        }

        td,
        th {
            &:nth-child(1) {
                width: 130px;
            }

            &:nth-child(2) {
                width: 130px;
            }

            &:nth-child(3) {
                width: 120px;
            }

            &:nth-child(4) {
                width: 100px;
            }

            &:nth-child(5) {
                width: 130px;
            }

            &:nth-child(6) {
                width: 150px;
            }
        }

        tbody {
            display: block;
            height: 410px;
            overflow-y: auto;
            border-right: 1px solid $border-color;
            border-left: 1px solid $border-color;

            tr {
                display: block;
                width: 100%;
            }

        }
    }
}

.amt-edit-opt {
    display: flex;
    align-items: center;
    margin-top: 3px;

    .btn-primary {
        min-width: auto;
        width: 56px;
        height: 32px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    i {
        font-size: 20px;
        color: $heading-color !important;
        margin-left: 5px;
        cursor: pointer;
    }

}

.hightlighted-txt {
    background: #E7FBFF;
    border: 1px solid #4E4E4E;
}


.bulk-upload-history-popup {
    .modal-main {
        width: 1050px;
    }

    .modal-body {
        max-height: 650px;
    }

    .bulk-upload-history-content {
        display: flex;
        justify-content: space-between;

        .bulk-upload-sec {
            width: 465px;

            .image-uploader-wrapper {
                .display-box {
                    min-height: 467px;
                }
            }
        }

        h3 {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            padding-bottom: 20px;
        }

        .bulk-history-sec {
            width: 465px;

            .bulk-history-table {
                background: #EFF2F9;
                border-radius: 8px;
                width: 100%;
                height: 465px;

                .data-table {
                    table {
                        tr {

                            th,
                            td {
                                &:nth-child(1) {
                                    width: 160px;
                                    padding-left: 20px;
                                }

                                &:nth-child(2) {
                                    width: 131px;
                                }

                                &:nth-child(3) {
                                    width: 110px;
                                }

                                &:nth-child(4) {
                                    width: 75px;
                                }
                            }

                            th {
                                position: sticky;
                                top: 0;
                                z-index: 10;
                                left: 0;
                                background: #e3e7f1;
                            }
                        }

                        thead {
                            display: block;
                        }

                        tbody {
                            display: block;
                            height: 410px;
                            overflow-y: auto;
                            border-right: 1px solid $border-color;
                            border-left: 1px solid $border-color;

                            tr {
                                display: table;
                                width: 100%;
                            }

                        }
                    }

                    .record-list-data {
                        display: flex;
                        align-items: center;

                        li {
                            &:nth-child(2) {
                                color: rgba(224, 32, 32, 1);
                                padding: 0px 14px;
                                position: relative;

                                &::after {
                                    content: '';
                                    height: 3px;
                                    width: 3px;
                                    border-radius: 100%;
                                    position: absolute;
                                    top: 10px;
                                    right: 5px;
                                    background: $heading-color;

                                }

                                &::before {
                                    content: '';
                                    height: 3px;
                                    width: 3px;
                                    border-radius: 100%;
                                    position: absolute;
                                    top: 10px;
                                    left: 5px;
                                    background: $heading-color;


                                }

                            }
                        }
                    }

                    .action-btn {
                        height: 32px;
                        width: 32px;
                        min-width: 32px;
                        border-radius: 4px;
                        border: 1px solid #e0e0e0;
                        margin-right: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        cursor: pointer;
                        color: $heading-color;

                    }
                }
            }
        }

        .payment-upload-succss {
            margin-top: 0px;
            background: #EFF2F9;
            border-radius: 8px;
            height: 463px;
            padding: 60px 20px;

            .upload-btn-txt {
                background: $white;
                border-radius: 8px;
            }
        }

    }

    .history-no-data-txt {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: rgba($heading-color, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

    }
}

.view-timeline-popup {
    .modal-body {
        max-height: 450px;
        overflow-y: hidden;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }

    .modal-main {
        border-radius: 8px;
        width: 530px;
        padding: 40px 40px;

        h2 {
            padding: 0px 0 24px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }
    }

    .timeline-history-tabel {
        max-height: 230px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;
        padding: 0px 40px;
    }

    table {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0 10px;

        tbody {
            tr {
                background: #eff2f9;

                td {
                    font-size: 11px;
                    line-height: 18px;
                    color: rgba($heading-color, 0.6);
                    padding: 14px 20px;

                    &:nth-child(1) {
                        width: 80px;
                        padding-left: 0px;
                        background: $white;
                    }

                    &:nth-child(2) {
                        width: calc(100% - 80px);
                        border-radius: 8px;
                    }

                    &:nth-child(3) {
                        border-radius: 0 8px 8px 0;
                    }

                    .date {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 20px;
                        color: $heading-color;
                        display: block;
                    }

                    .time {
                        font-size: 10px;
                        line-height: 20px;
                        color: rgba($heading-color, 0.6);
                        display: block;
                    }

                    .heading {
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 20px;
                        color: $heading-color;
                        display: block;
                        font-weight: 400;
                    }

                    .subheading {
                        font-size: 11px;
                        line-height: 18px;
                        color: rgba($heading-color, 0.6);
                        display: block;
                    }
                }

                &:hover {
                    background: #bff8fc;
                }
            }
        }
    }

    &.doc-history-timeline {
        table {
            tbody {
                tr {
                    td {
                        padding: 14px 15px;

                        &:nth-child(3) {
                            border-radius: 0 !important;
                            width: 90px;
                        }

                        &:nth-child(4) {
                            border-radius: 0 8px 8px 0 !important;
                            vertical-align: text-bottom;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .form-filed {
        width: 100%;
        margin-right: 0px;
    }
}

.remark-heading-txt {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: $heading-color;
    padding: 30px 0 13px;
    display: block;
}

.confirmation-popup {
    .confirmation-btn {
        margin: 0 auto;
        text-align: center;

        .btn-primary,
        .btn-line {
            min-width: 125px;
            margin: 0px 7px
        }
    }

    .modal-main {

        h2 {
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
        }
    }

}

.tooltip {
    position: relative;
    float: right;
}

.tooltip>.tooltip-inner {
    background-color: #eebf3f;
    padding: 5px 15px;
    color: rgb(23, 44, 66);
    font-weight: bold;
    font-size: 13px;
}

.popOver+.tooltip>.tooltip-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #eebf3f;
}

section {
    margin: 140px auto;
    height: 1000px;
}

.progress {
    border-radius: 0;
    overflow: visible;
}

.progress-bar {
    background: rgb(23, 44, 60);
    -webkit-transition: width 1.5s ease-in-out;
    transition: width 1.5s ease-in-out;
}

.span-link {
    text-decoration: underline !important;
    cursor: pointer;

}

.approve-color {
    color: #59B74A;
}

.rejected-color {
    color: #FF3636;
}

.no-data {
    text-align: center !important;
    padding-bottom: 20px !important;
    color: red !important;
    font-size: 12px !important;
}

.page-not-found {
    position: relative;
    height: 83vh;

    .container {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30%;

        h1 {
            font-size: 100px;
            font-weight: bold;
        }

        h2 {
            margin-top: 60px;
            font-size: 60px;
        }
    }
}

.wordWrap {
    overflow-wrap: break-word;
}

.resize {
    resize: none;
}

.confirmation-popup {
    .bulkupload {
        .btn-primary {
            min-width: 175px;
        }
    }
}

.addposition {
    margin-left: -15px !important;
    font-size: 13px !important;
}

.hidden {
    opacity: 0;
}

.tdWd{
    width: 1% !important;
}



.if-detail-table {
    padding-top: 30px;

    .custom-control {
        display: inline-block;
    }

    .ic-keyboard_arrow_down {
        font-size: 26px;
    }

    .data-table {
        table { 
            tr {

                th,
                td {
                    &:nth-child(1) {
                        width: 5%;
                        padding-left: 20px;
                    } 
  
                    &.net-amt-txt {
                        background: rgba(255, 241, 214, 0.4);
                    }
                }

                td {
                    vertical-align: top;
                }

                th {
                    .info-icn {
                        display: inline-block;
                        position: relative;

                        .info-text-detail {
                            left: -212px;
                        }
                    }
                }

                .loan-id-arrow {
                    &::before {
                        content: "\e917";
                        font-family: dc !important;
                        font-size: 24px;
                        position: relative;
                        top: 6px;
                        margin-right: 8px;
                        cursor: pointer;
                    }
                }

                &.active {
                    background: #F6F6F6 !important;
                    border-bottom: 0px;

                    .loan-id-arrow {
                        &::before {
                            content: "\e91a";
                            font-family: dc !important;
                            font-size: 24px;
                            position: relative;
                            top: 6px;
                            margin-right: 8px;
                        }
                    }
                }
            }

            .subtable { 
                .subtable-wrapper {
                    padding-left: 100px;
                    padding-right: 100px;
                }
                thead {
                    tr {
                        background: #E3E7F1;

                        th {
                            &:first-child {
                                padding-left: 50px !important;
                            }
                        }
                    }
                }

                td {
                    background: #F6F6F6;
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                    padding-top: 0px;
                    vertical-align: middle;

                    table {
                        tr {
                            th {
                                width: 11%; 
                            }
                        }
                    }
                }

                table { 
                    tbody {
                        td {
                            background: $white !important;
                            padding-left: 20px !important;
                            padding-right: 20px !important;
                            padding-top: 13px;

                            &:first-child {
                                padding-left: 50px !important;
                            }
                        }
                    }
                }

                tr {
                    &.rejected {
                        td {
                            opacity: 0.4;

                            &:last-child {
                                color: #FF3636;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .ic-file_icn {
                position: absolute;
                margin-left: -22px;
                color: #000;
                font-size: 17px;
            }

            .ic-check {
                color: #59B74A;
            }

            .ic-clearclose {
                color: #FF3636;
            }
        }

        .link-txt {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .action-btn {
        display: flex;
        align-items: center;

        li {
            height: 32px;
            width: 32px;
            min-width: 32px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
            color: $heading-color;

            .ic-email1 {
                font-size: 22px;
            }

            .ic-otp {
                font-size: 23px;
            }
        }
    }
}


.if-summery-right-panel {
    position: absolute;
    right: 15px;

    // z-index: 1;
    ul {
        display: flex;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            color: rgba($heading-color, 0.7);
            font-size: 22px;
            padding-right: 15px;
            text-align: center;

            span {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                display: block;
            }

            label {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                display: block;
                color: rgba($heading-color, 0.6);
            }

            .total-payout-txt {
                color: #34C48B;
            } 

        }
    }
}

.if-upload-pdf {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    
    .btn-line {
        padding: 0px 20px;
        display: flex;
        align-items: center;

        .ic-upload {
            font-size: 20px;
            padding-right: 5px;
        }

        &:hover {
            i {
                color: $white;
            }
        }
    }
}

.if-payout-total{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .total-payout-txt {
        color: #34C48B;
    }
}

.float-right{
    float: right;
}